//src/api/brandapi.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */
/*产品规格接口---------------------------*/
//获取产品收费项列表接口
export function getproductSkuData(obj) {
    return request({
        url: '/api/admin/cate-charge',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增产品收费项接口
export function postaddProductSku(obj,url) {
    return request({
        url: '/api/admin/cate-charge/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑产品收费项接口
export function posteditProductSku(obj,url) {
    return request({
        url: '/api/admin/cate-charge/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除产品收费项接口
export function postdelProductSku(obj,url) {
    return request({
        url: '/api/admin/cate-charge/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}

//获取产品收费项规格值列表接口
export function getproductSkuValData(obj) {
    return request({
        url: '/api/admin/cate-charge/price',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增产品收费项规格值接口
export function postaddProductSkuVal(obj,url) {
    return request({
        url: '/api/admin/cate-charge/price/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑产品收费项规格值接口
export function posteditProductSkuVal(obj,url) {
    return request({
        url: '/api/admin/cate-charge/price/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除产品收费项规格值接口
export function postdelProductSkuVal(obj,url) {
    return request({
        url: '/api/admin/cate-charge/price/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}

