<template>
  <div class="about">
    <el-table
        :data="tableData"
        size="medium"
        height="calc(100vh - 145px)"
        row-key="id"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <el-table-column
          prop="name"
          label="分类名称">
      </el-table-column>
      <el-table-column
          width="60"
          prop="sequence"
          label="排序">
      </el-table-column>
      <el-table-column
          width="60"
          prop="id"
          label="ID">
      </el-table-column>
      <el-table-column
          prop="text"
          width="250"
          label="操作">
        <template #header>
          <el-button  @click="add()" size="mini" type="primary" style="margin:10px 0;">新增</el-button>
        </template>
        <template #default="scope">
          <div style="margin: 5px 0">
            <el-button-group>
              <el-button size="mini" type="primary" icon="el-icon-circle-plus-outline" @click="add(scope.row)"></el-button>
              <el-button size="mini" type="primary"  @click="addfat(scope.row,)">收费项规格</el-button>
              <el-button size="mini" type="primary" icon="el-icon-edit" @click="upData(scope.row)"></el-button>
              <el-button size="mini"  type="primary" icon="el-icon-delete" @click="deleData(scope.row)"></el-button>
            </el-button-group>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-drawer
        :title="drawerName"
        v-model="drawer"
        size="30%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm">
        <el-form-item label="分类名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="上级分类" prop="father_id">
          <el-cascader
              placeholder=""
              ref="cascaderHandle"
              style="width: 100%"
              :options="fatherArr"
              @change="befor"
              v-model="ruleForm.father_id"
              :props="{

                          label: 'name',
                          value: 'id',
                          children: 'children',
                          checkStrictly: true
                          }"
              :filterable="true">
          </el-cascader>
        </el-form-item>
        <el-form-item label="排序" >
          <el-input-number style="width: 100%" :min="0" controls-position="right" v-model="ruleForm.sequence"></el-input-number>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
          <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
    <el-drawer title="产品收费项"
               v-model="drawerCha"
               size="60%"
               direction="rtl"
               :close-on-click-modal="false"
               destroy-on-close>
      <Charge :nowData="nowData"></Charge>
    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import {postCateTree, getCharge} from "@/api/common";
import * as axios from "@/api/classficaApi"//本页面接口
import Charge from '../../components/classificaChild/cateCharge'
export default {
  name: 'fatherArr',
  components:{
    Charge,
  },
  data(){
    return{
      drawerName:'',
      login:false,
      drawer:false,
      drawerCha:false,
      nowData:{
        category_id:'',
      },
      tableData:[],
      fatherArr:[],
      ruleForm:{
        name:'',
        father_id:'',
        sequence:50,
      },
      rules:{
        name: [
          { required: true, message: '请填写', trigger: 'blue' },
        ],
        father_id: [
          { required: true, message: '请选择', trigger: 'blue' },
        ],
      }
    }
  },
  created() {
    this.onTable()
    this.onArea()
  },
  methods:{
    befor(val){
      console.log(val)
    },
    onArea(){
      postCateTree().then((res)=>{
        this.fatherArr=res.data
        this.fatherArr.unshift({
          name:'顶层分类',
          id:0
        })
      }).catch((err)=>{

      })
    },
    onTable(){
        axios.getCategoryData().then((res)=>{
          console.log(res)
          this.tableData=res.data
          console.log('this.tableData',this.tableData)
        }).catch((err)=>{

        })
    },
    addfat(row){
      this.nowData.category_id=row.id
      this.drawerCha=true
    },
    add(row,val){
      this.drawerName='分类新增'
      this.ruleForm= {
        name:'',
        father_id:'',
        sequence:50,
      }
      if(row){
        this.ruleForm.father_id=row.id
      }
      this.drawer=true
    },
    upData(row){
      this.drawerName='分类修改'
      this.ruleForm.id=row.id
      this.ruleForm.name=row.name
      this.ruleForm.father_id=row.father_id
      this.ruleForm.sequence=Number(row.sequence)
      this.drawer=true
    },
    okAdd(formName,val){
      if(this.login){
        return false
      }
      // this.ruleForm.father_id=JSON.stringify(this.ruleForm.father_id)
      this.login=true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(val==1){
            axios.postaddCategory(this.ruleForm).then((res)=>{
                if(res.code==200){
                  this.$message.success(res.msg)
                  this.onTable()
                  this.onArea()
                  let that=this
                  setTimeout(function () {
                    that.drawer=false
                    that.login=false
                  },1000)
                }else {
                  this.login=false
                  this.$message.error(res.msg)
                }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.posteditCategory(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                this.onArea()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }


        } else {
          this.login=false
          console.log('error submit!!')
          return false
        }
      })
    },
    deleData(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            axios.postdelCategory({id:row.id}).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },

  }
}
</script>
<style lang="less" >
.avatar-uploader .el-upload {
  border: 1px dashed #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload{

}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-table .el-table__cell{
    padding: 0;
}
</style>