//src/api/brandapi.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */
/*收费项接口---------------------------*/
//获取收费项表接口
export function getCategoryData(obj) {
    return request({
        url: '/api/admin/category',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增收费项接口
export function postaddCategory(obj,url) {
    return request({
        url: '/api/admin/category/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑收费项接口
export function posteditCategory(obj,url) {
    return request({
        url: '/api/admin/category/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除收费项接口
export function postdelCategory(obj,url) {
    return request({
        url: '/api/admin/category/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
/*地区接口---------------------------*/
//获取地区列表接口
export function getAreaData(obj) {
    return request({
        url: '/api/admin/area',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增地区接口
export function postaddArea(obj,url) {
    return request({
        url: '/api/admin/area/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑地区接口
export function posteditArea(obj,url) {
    return request({
        url: '/api/admin/area/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除地区接口
export function postdelArea(obj,url) {
    return request({
        url: '/api/admin/area/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
/*产品详情接口---------------------------*/
//获取产品详情列表接口
export function getproductConData(obj) {
    return request({
        url: '/api/admin/product/content',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增产品详情接口
export function postaddProductCon(obj,url) {
    return request({
        url: '/api/admin/product/content/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑产品详情接口
export function posteditProductCon(obj,url) {
    return request({
        url: '/api/admin/product/content/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除产品详情接口
export function postdelProductCon(obj,url) {
    return request({
        url: '/api/admin/product/content/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
